<template>
  <!-- BEGIN: Content-->
  <div class="app-content content ">
    <div class="content-overlay" />
    <div class="header-navbar-shadow" />
    <div class="content-wrapper">
      <div class="content-header row">
        <div class="content-header-left col-md-9 col-12 mb-2">
          <div class="row breadcrumbs-top">
            <div class="col-12">
              <h2 class="content-header-title float-start mb-0">{{ item.id ? 'Edit' : 'New' }} admin assistant
              </h2>
            </div>
          </div>
        </div>
        <div class="content-header-right text-md-end col-md-3 col-12 d-md-block d-none">
          <div class="mb-1 breadcrumb-right">
            <a
              class="btn btn-icon btn-secondary"
              @click="$router.push({name: 'researcher.administration.collaborators.index'})"
            ><i data-feather="chevron-left" /></a>
          </div>
        </div>
      </div>
      <div class="content-body content-body--senior-call">
        <div class="row">
          <div class="col-12">
            <SearchResearcher :item="item" />

            <div class="card">
              <div class="card-header">
                <h3 class="cart-title">
                  <a data-action="collapse">Basic information</a>
                </h3>
              </div>
              <div class="card-content">
                <div class="card-body">
                  <div class="row">
                    <div class="col-2">
                      <div class="mb-1 form-group required">
                        <label
                          for=""
                          class="form-label"
                        >{{ labels['content.first_name'] }}</label>
                        <input
                          v-model="item.first_name"
                          type="text"
                          class="form-control"
                        >
                      </div>
                    </div>
                    <div class="col-3">
                      <div class="mb-1 form-group required">
                        <label class="form-label">
                          {{ labels['content.last_name'] }}
                        </label>
                        <input
                          v-model="item.last_name"
                          aria-autocomplete="off"
                          autocomplete="new-surname-1"
                          class="form-control"
                          type="text"
                        >
                      </div>
                    </div>
                    <div
                      class="col-sm-1 my-auto"
                    >
                      <div class="form-check form-check-success form-switch d-flex align-items-center">
                        <input
                          id="publishContent"
                          v-model="item.active"
                          type="checkbox"
                          class="form-check-input"
                        >
                        <label
                          class="ms-1 form-check-label"
                          for="publishContent"
                        >{{ labels['active'] }}</label>
                      </div>
                    </div>
                    <div class="col-4">
                      <div class="mb-1 form-group required">
                        <label class="form-label">
                          {{ labels['content.email'] }}
                        </label>
                        <input
                          v-model="item.email"
                          aria-autocomplete="off"
                          autocomplete="new-surname-1"
                          class="form-control"
                          type="text"
                        >
                      </div>
                    </div>
                    <div class="col-2">
                      <div class="mb-1 form-group required">
                        <label class="form-label">
                          {{ labels['content.password'] }}
                        </label>
                        <input
                          v-model="item.password"
                          class="form-control"
                          placeholder="******"
                          type="text"
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              v-if="!loading"
              class="row"
            >
              <div class="col-12">
                <div class="card">
                  <div class="card-header">
                    <h3 class="cart-title">
                      <a data-action="collapse">Manage permissions</a>
                    </h3>
                  </div>
                  <div class="card-body">
                    <div class="row">
                      <div class="col-12">
                        <input
                          v-model="filters.name"
                          class="form-control mb-1"
                          placeholder="Filter permissions here..."
                          type="text"
                        >
                      </div>
                    </div>
                    <table class="table table-stripe">
                      <thead>
                        <tr>
                          <th>Section</th>
                          <th>Permissions</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(permission, index) in filterPermissions"
                          :key="index"
                        >
                          <td class="text-capitalize">
                            {{ index }}
                          </td>
                          <td class="text-capitalize">
                            <template v-for="custom, key in permission">
                              <template v-if="item.permissions && item.permissions.findIndex(f => f.id === custom.id) >= 0">
                                <span
                                  :key="key"
                                  :class="`badge ${key > 0 ? 'ms-50' : ''} badge-light-${item.permissions[item.permissions.findIndex(f => f.id === custom.id)].privacy == 'all' ? 'success' : 'info'}`"
                                >
                                  {{ `${custom.name}` }}
                                </span>
                              </template>
                            </template>
                          </td>
                          <td>
                            <a
                              class="nav-link active"
                              aria-current="page"
                              @click="openOffcanva(index, permission)"
                            >
                              <i
                                class="me-50"
                                data-feather="edit-3"
                              /> Edit
                            </a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <ItemInfoSheet @closed="savePermissions">
                <template #info-sheet-title>
                  Manage permissions - {{ selectedItemTitle }}
                </template>
                <template #info-sheet>
                  <div class="offcanvas-body">
                    <div
                      v-for="permission, index in selectedItem"
                      :key="index"
                      class="mb-2"
                    >
                      <label
                        for=""
                        class="form-label text-capitalize"
                        style="font-size: 16px"
                      >{{ permission.name }}</label>
                      <br>
                      <div
                        class="btn-group"
                        role="group"
                        aria-label="Message mentions"
                      >
                        <input
                          :id="`none-${index}`"
                          type="radio"
                          class="btn-check"
                          :name="`options-${index}`"
                          autocomplete="off"
                          :checked="!item.permissions || item.permissions.filter(e => {
                            return e.name == permission.name
                          }).length === 0"
                          @click="changePermission(permission)"
                        >
                        <label
                          class="btn btn-outline-secondary"
                          :for="`none-${index}`"
                        >No</label>

                        <input
                          :id="`attached-researcher-${index}`"
                          type="radio"
                          class="btn-check"
                          :name="`options-${index}`"
                          autocomplete="off"
                          :checked="item.permissions && item.permissions.filter(e => {
                            return e.name == permission.name && e.privacy == 'attached-researcher'
                          }).length > 0"
                          @click="changePermission(permission, 'attached-researcher')"
                        >
                        <label
                          class="btn btn-outline-secondary"
                          :for="`attached-researcher-${index}`"
                        >Yes</label>
                      </div>
                    </div>
                  </div>
                  <div class="offcanvas-footer mt-auto">
                    <a
                      title="apply actions"
                      class="btn btn-primary mb-1 d-grid w-100"
                      @click="savePermissions"
                    >Close</a>
                  </div>
                </template>
              </ItemInfoSheet>
            </div>
          </div>
        </div>
        <div class="page-actions">
          <button
            v-if="!loading"
            id="type-success"
            class="btn btn-success"
            @click="save"
          >
            <i data-feather="save" /> <span class="ms-25 align-middle"> Save and Exit</span>
          </button>
          <button
            v-if="loading"
            class="btn btn-outline-success"
            type="button"
            disabled=""
          >
            <span
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            />
            <span class="ms-25 align-middle">Saving...</span>
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- END: Content-->
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import ItemInfoSheet from '@/views/back/partials/offcanvas/ItemInfoSheet.vue'
import SearchResearcher from '../../partials/components/SearchResearcher.vue'

export default {
  components: {
    SearchResearcher,
    ItemInfoSheet,
  },
  data() {
    return {
      itemId: this.$route.params.id,
      loading: true,
      selectedItemTitle: '',
      selectedItem: {},
      filters: {},
      hidePermissions: [ // Collaborators can never to this things
        'alerts',
        'personal data',
        'scientific production report',
        'Memoir profiles',
        'Memoir highlights',
        'administrative data',
        'payrolls',
        'occupational safeties',
        'insurances',
        'knowledge base',
        'collaborators',
      ],
    }
  },
  computed: {
    ...mapGetters({
      item: 'collaborators/item',
      permissions: 'permissions/permissions',
      labels: 'sectionLabels/itemLabels',
      loggedUser: 'auth/admin',
    }),
    filterPermissions() {
      return Object.keys(this.permissions).filter(key => {
        if (!this.hidePermissions.includes(key)) {
          if (this.filters.name) {
            return key.includes(this.filters.name)
          }

          return true
        }

        return false
      }).reduce((res, key) => (res[key] = this.permissions[key], res), {})
    },
  },
  async mounted() {
    this.$store.dispatch('sectionLabels/fetchLabels', 'masters.users')
    this.$store.dispatch('permissions/fetch', !this.loggedUser.roles.includes('super-admin') ? this.loggedUser.id : false)
    if (this.itemId) {
      await this.$store.dispatch('collaborators/fetchItem', this.itemId)
    } else {
      this.$store.dispatch('collaborators/clear')
    }

    this.loading = false
  },
  methods: {
    openOffcanva(title, permissions) {
      this.selectedItemTitle = title
      this.selectedItem = permissions
      this.$store.dispatch('modals/toggleInfoItemSheet', true)
    },
    changePermission(permission, privacy = null) {
      const index = this.item.permissions.findIndex(e => e.id === permission.id)
      if (index >= 0) {
        if (!privacy) {
          this.item.permissions.splice(index, 1)
        } else {
          this.item.permissions[index] = {
            id: permission.id,
            name: permission.name,
            privacy,
          }
        }
      } else if (privacy) {
        if (!this.item.permissions) {
          this.item.permissions = []
        }

        this.item.permissions.push({
          id: permission.id,
          name: permission.name,
          privacy,
        })
      }

      this.$forceUpdate()
    },
    async save() {
      this.loading = true
      try {
        if (this.itemId) {
          await this.$store.dispatch('collaborators/update', { id: this.itemId, data: this.item })
        } else {
          await this.$store.dispatch('collaborators/create', this.item)
        }
        this.errors = ''
      } catch (e) {
        this.errors = e.response.data.message
      }

      if (this.errors === '' && this.item.id) {
        Vue.swal('Collaborator updated successfully!', '', 'success').then(() => {
          this.$router.push({ name: 'researcher.administration.collaborators.index' })
        })
      } else {
        // Vue.swal
      }

      this.loading = false
    },
    savePermissions() {
      this.selectedItemTitle = ''
      this.selectedItem = {}
      this.$store.dispatch('modals/toggleInfoItemSheet', false)
    },
    async onSearch(search, store) {
      search.active = true
      if (search.name !== '') {
        await this.$store.dispatch(store, search)
      }
    },
  },
}
</script>
